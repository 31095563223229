import { Router } from "@reach/router"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../../../components/templates/layout"
import Details from "../../../components/views/retail/orders/details"

const Orders = () => {
  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <Layout>
          <Details />
        </Layout>
      </DndProvider>
    </React.Fragment>
  )
}

export default Orders
